const scrollAnchor = () => {
  let anchor = document.querySelector('h2')
  let headerOffset = 200;
  let position = anchor.offsetTop
  let targetPosition = position - headerOffset

  window.scrollTo({
    top: targetPosition,
    left: 0,
    behavior: 'smooth'
  })
}


const positionIframe = () => {
  const iframes = document.querySelectorAll('iframe')
  if (!iframes.length) return

  iframes.forEach(iframe => {
    const width = iframe.getAttribute('width')
    const height = iframe.getAttribute('height')
    if (!width || !height) {
      console.error('iframe must have width and height attributes')
      return
    }
    const aspectRatio = width / height
    const inverseAspectRatio = height / width

    const parent = iframe.parentElement

    if (parent.nodeName !== 'FIGURE') {
      // create a parent wrapper elem and wrap iframe in it
      const newWrapper = document.createElement('figure')
      parent.insertBefore(newWrapper, iframe)
      newWrapper.appendChild(iframe)
      addStylesForIframe(iframe, newWrapper, inverseAspectRatio)
    } else {
      addStylesForIframe(iframe, parent, inverseAspectRatio)
    }

  })
}

const addStylesForIframe = (iframe, parent, inverseAspectRatio) => {
	const isXl = window.matchMedia("(min-width:1024px)")
	if (inverseAspectRatio > 1 && isXl.matches) {
		parent.style.maxWidth = '50%'
    parent.style.paddingTop = `${inverseAspectRatio * 100 * 0.5}%`
	} else {
		parent.style.maxWidth = '100%'
    parent.style.paddingTop = `${inverseAspectRatio * 100}%`
	}

    parent.style.position = 'relative'
    parent.style.overflow = 'hidden'
    parent.style.width = '100%'
    parent.style.marginTop = '1rem';
    iframe.style.position = 'absolute'
    iframe.style.inset = '0px'
    iframe.style.width = '100%'
    iframe.style.height = '100%'
}

window.addEventListener('resize', positionIframe)

document.addEventListener('DOMContentLoaded', positionIframe)